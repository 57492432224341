//  Core

.custom-confirm-dialog {
  & .MuiDialogTitle-root {
    border-bottom-color: #dcdef1;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  & .MuiDialogContent-root {
    border-bottom-color: #dcdef1;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
}
